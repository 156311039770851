<template>
  <!-- 查看数据质量信息 -->
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据管理</el-breadcrumb-item>
<!--      <el-breadcrumb-item :to="{ path: '/dataManage-dataList/dataList' }">{{dpCrumb}} 系统数据列表</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item :to="{ path: '/dataList-checkData?systemId=' + systemId}">{{preCrumb}} 设备数据列表</el-breadcrumb-item>-->
      <el-breadcrumb-item><span @click="back(-2)" style="font-weight:bold">{{dpCrumb}} 系统数据列表</span></el-breadcrumb-item>
      <el-breadcrumb-item><span @click="back(-1)" style="font-weight:bold">{{preCrumb}} 设备数据列表</span> </el-breadcrumb-item>
      <!--            <el-breadcrumb-item >查看</el-breadcrumb-item>-->
      <el-breadcrumb-item >{{crumb}} 详细数据</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="表格信息" name="chart">
        <chart v-if="activeName == 'chart'"></chart>
      </el-tab-pane>
      <el-tab-pane label="图表信息" name="graph">
        <graph v-if="activeName == 'graph'"></graph>
      </el-tab-pane>
    </el-tabs>

    <!-- <detailDia v-if="detailVisible" ref="detailDialog"></detailDia> -->
    <more v-if="moreVisible" ref="moreDialog"></more>
    <pointnum v-if="pointnumVisible" ref="pointnumDialog"></pointnum>
  </div>
</template>

<script>

import chart from "../dataDetail/dataDetail_chart";
import graph from "../dataDetail/dataDetail_graph";

import more from "./more";
// import pointnum from "./pointnum";
export default {

  name: "dataDetail",
  data(){
    return{
      moreVisible:false,
      activeName: 'chart',
      pointnumVisible:false,
      systemId: undefined,
      preCrumb: '',
      dpCrumb: '',
      crumb: ''
    }
  },
  components: {
    more,
    chart,
    graph
  },
  mounted() {
    this.systemId = this.$route.query.systemId
    this.preCrumb = this.$route.query.pCrumb
    this.dpCrumb = this.$route.query.dpCrumb
    this.crumb = this.$route.query.crumb
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    back(count) {
      history.go(count)
    }
  },

}
</script>


<style scoped>

</style>
