<template>
    <!-- 查看数据质量信息 -->
    <el-dialog title="查看详细信息" :visible.sync="visible" width="65%" >
    <!-- <div class="detail">
        <div class="item">
            <span class="info">数据项名: {{checkForm.pointname}}</span></br>
            <span class="info">测量数值: {{checkForm.num}}</span></br>
            <span class="info">设备名: {{checkForm.deviceName}}</span></br>
            <span class="info">设备描述: {{checkForm.describe}}</span></br>
            <span class="info">所属设备: {{checkForm.device}}</span></br>
            <span class="info">预警阈值: {{checkForm.threshold}}</span></br>
            <span class="info">数据类型: {{checkForm.dataType}}</span></br>
            <span class="info">工程单位: {{checkForm.unit}}</span></br>
            <span class="info">采集频率: {{checkForm.frequency}}</span></br>
            <span class="info">备注: {{checkForm.remark}}</span></br>
            <span class="info">数据质量: {{checkForm.dataQuality}}</span>
        </div>
        <div class="item ">2</div>
        <div class="item ">3</div>
        <div class="item ">4</div>
    </div> -->
 
    <el-row style="background: #eeeeee" type="flex" justify="center">
        <el-col >
            <el-card class="box-card" shadow="never">
                
                <span class="info">数据项名: </span>{{checkForm.pointname}}<br>
                <span class="info">测量数值:</span>{{checkForm.num}}<br>
                <span class="info">设备名: </span>{{checkForm.deviceName}}<br>
                <span class="info">设备描述: </span>{{checkForm.describe}}<br>
                <span class="info">所属设备: </span>{{checkForm.device}}<br>
                <span class="info">预警阈值: </span>{{checkForm.threshold}}<br>
                <span class="info">数据类型: </span>{{checkForm.dataType}}<br>
                <span class="info">工程单位: </span>{{checkForm.unit}}<br>
                <span class="info">采集频率: </span>{{checkForm.frequency}}<br>
                <span class="info">备注: </span>{{checkForm.remark}}<br>
                <span class="info">数据质量: </span>{{checkForm.dataQuality}}
            </el-card>

            <el-card class="box-card" shadow="never">
                
                <span class="info">数据项名: </span>{{checkForm.pointname}}<br>
                <span class="info">测量数值:</span>{{checkForm.num}}<br>
                <span class="info">设备名: </span>{{checkForm.deviceName}}<br>
                <span class="info">设备描述: </span>{{checkForm.describe}}<br>
                <span class="info">所属设备: </span>{{checkForm.device}}<br>
                <span class="info">预警阈值: </span>{{checkForm.threshold}}<br>
                <span class="info">数据类型: </span>{{checkForm.dataType}}<br>
                <span class="info">工程单位: </span>{{checkForm.unit}}<br>
                <span class="info">采集频率: </span>{{checkForm.frequency}}<br>
                <span class="info">备注: </span>{{checkForm.remark}}<br>
                <span class="info">数据质量: </span>{{checkForm.dataQuality}}
            </el-card>
        </el-col>
    </el-row>

    
</el-dialog>
</template>

<script>
    export default {
        name: "checkSystem",
        data() {
            return {
                visible: false,
                checkForm: {
                    pointname: '',
                    num: '',
                    deviceName: '',
                    describe: '',
                    device: '',
                    threshold: '',
                    dataType: '',
                    unit: '',
                    frequency: '',
                    remark: '',
                    dataQuality: ''
                }
            }
        },
        methods: {
            init(id) {
                this.visible = true;
                this.checkForm.pointname = id.pointname;
                this.checkForm.num = id.num;
                this.checkForm.deviceName = id.deviceName;
                this.checkForm.describe = id.describe;
                this.checkForm.device = id.device;
                this.checkForm.threshold = id.threshold;
                this.checkForm.dataType = id.dataType;
                this.checkForm.unit = id.unit;
                this.checkForm.frequency = id.frequency;
                this.checkForm.remark = id.remark;
                this.checkForm.dataQuality = id.dataQuality;
            }
        }
    }
</script>

<style scoped>
    .el-card {
        background: #fbfbfb;
        width: 400px;
        /* 一行一行显示 */
        display: inline-block;
        margin: 5px;
        border-radius: 20px;
    }
    /* .detail {
        display: grid;
        height: 620px;
        width: 300px;
        grid-template-columns: 425px 425px;
        grid-template-rows: 310px 310px;
        
    } */
    
    /*.item {*/
    /*    font-size: 20px;*/
    /*    text-align: center;*/
    /*    text-justify: center;*/
    /*}*/
    
    .info {
        width: 415px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 14px;
        /* text-align: left; */
        /* float: left; */
        margin-left: 5px;
        color: cornflowerblue;
    }
</style>